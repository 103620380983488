<div class="main-container">
  <div class="info-page-root-container">
    <div class="info-page-header-banner">
      <img
        _ngcontent-ng-c2624143390=""
        src="../../../assets/images/logo.svg"
        alt="Brand logo"
      />
    </div>
    <div class="tnc-info">
      <p
        *ngIf="tcContent"
        class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle font-secondary"
      >
        Terms & Conditions 
      </p>
      <p
        *ngIf="faqContent"
        class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle font-secondary"
      >
        Frequently Asked Questions
      </p>
      <p
        *ngIf="privacyPolicy"
        class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle font-secondary"
      >
        Privacy Policy
      </p>
    </div>
  </div>

  <div class="container mt-2">
    <div *ngIf="tcContent" class="mat-typography ql-editor">
      <div class="content-div" [innerHTML]="tcContent"></div>
    </div>
    <div *ngIf="faqContent" class="mat-typography ql-editor">
      <div class="content-div" [innerHTML]="faqContent"></div>
    </div>
    <div *ngIf="privacyPolicy" class="mat-typography ql-editor">
      <div class="content-div" [innerHTML]="privacyPolicy"></div>
    </div>
  </div>
</div>
