import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {MatButtonModule} from '@angular/material/button';
import { EntryComponent } from './pages/entry/entry.component';
import { EntryActivationComponent } from './pages/entry-activation/entry-activation.component';
import { MessageDialogComponent } from './common/components/message-dialog/message-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaSettings, RecaptchaV3Module } from "ng-recaptcha-2";
import { environment } from '../environments/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { FaqComponent } from './pages/faq/faq.component';
import { SampleReceiptDialogComponent } from './pages/entry/sample-receipt-dialog/sample-receipt-dialog.component';




@NgModule({
  declarations: [
    AppComponent,
    EntryComponent,
   EntryActivationComponent,
   MessageDialogComponent,
   TermsConditionsComponent,
   FaqComponent,
   SampleReceiptDialogComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
    RecaptchaV3Module,
    MatDialogModule,
    MatFormFieldModule,
    
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue:"6LcCYe8bAAAAADbDjiNWMEfvWv5d1CQGWaShfF4R" },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
       size:"compact"
      } as RecaptchaSettings
    },
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
