
<h3 mat-dialog-title style="font-size: 16px; font-weight: 500;">
    {{data.title}}
  </h3>
  
  <div mat-dialog-content>
    <p>{{data.content}}</p>
    <p *ngIf="data.registermessage">{{data.registermessage}}</p>
    
  </div>
  
  <div mat-dialog-actions class="mdc-action-container">
    <!-- <div class="Continue-button-container">
      <button mat-raised-button class="Continue-button" *ngIf="data.registermessage"  (click)="openRegisterPage()">Continue</button>
    
    </div> -->
    <div class="ok-button-container" style="margin-left: auto;">
      <button mat-raised-button class="primary button" *ngIf="data.RegisterNow===true"  (click)="openRegisterPage()">Register NOW</button>
    <button mat-raised-button color="primary button"  *ngIf="status !== 'SUCCESS'" (click)="close()">OK</button>
    <button mat-raised-button color="primary button" *ngIf="status === 'SUCCESS' && data.RegisterNow!==true"  (click)="clearField()">Ok</button>
   
    
  </div>
  </div>
 

