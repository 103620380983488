
  <div mat-dialog-content>
    <img class="" src="assets/images/tax-invoice.jpg" alt="" height="400px"  style="width: 100%;
    object-fit: contain;
    height: 100%;"/>
  </div>
  
  <div mat-dialog-actions class="mdc-action-container">
    <button mat-raised-button color="primary" (click)="close()" >Close</button>
    <!-- <button mat-button>no</button> -->
  </div>