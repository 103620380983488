import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EntryComponent } from './pages/entry/entry.component';
import { EntryActivationComponent } from './pages/entry-activation/entry-activation.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';

const routes: Routes = [
  { component: EntryComponent, path: 'entry' },
  { component: EntryComponent, path: '' },
  { component: EntryActivationComponent, path: 'activation' },
  { component: TermsConditionsComponent, path: 'terms-conditions' },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 


}
