export const environment = {
    production: false,
    buildNo:202410310245,
    ver:'0.0.1',
    apiUrl:'https://dev.crm.mojosoft.work/ws',
    userId: 'crm',
    password: '1234',
    appKey: "e568458e9121478125d076b344e26c9e",
    crmSingupURL:'https://dev.welcome.stpierres.mojosoft.work/register'
  };
  