<div class="info-page-root-container">
    <div class="info-page-header-banner">
      <img _ngcontent-ng-c2624143390="" src="../../../assets/images/logo.svg" alt="Brand logo" >
    </div>
   
    
  </div>
<div class="customer-activation-main-container">
    <div class="customer-activation-main-div">
        <div class="customer-activation-details">
            @if(message==undefined){
            <p class="loading">Please wait....</p>
            }
            <p class="customer-activation-message">{{message}}</p>
        </div>
       
    </div>
</div>

