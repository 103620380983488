import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-sample-receipt-dialog',
  templateUrl: './sample-receipt-dialog.component.html',
  styleUrl: './sample-receipt-dialog.component.scss'
})
export class SampleReceiptDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<SampleReceiptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close() {
    this.dialogRef.close();
  }

}
