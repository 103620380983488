<div class="page-container">
  <div class="image-container group-2 group">
    <div class="overlap-group1">

      <div class="merch-items">
        <div class="overlap-group">
          <img src="../../../assets/img/combined.png" class="img-items" />
        </div>
      </div>
      <img class="sushi-plane-yellow-2-1" src="assets/img/sushi-plane-yellow-2-1.png" alt="Sushi-Plane-Yellow-2 1" />

    </div>


  </div>
  <div class="logo-entry-container">
    <img src="assets/img/stpierres-hut-1.png" style="width: 150px;">
    <h1 class="title">
      Enter your details below <br />to go in the draw to<br />WIN a Family Trip to Tokyo
    </h1>
    <p class="x1000s-more-prizes-drawn-weekly valign-text-middle">+ 1000s more prizes drawn weekly!</p>
    <form class="entry-form" [formGroup]="entryForm">
      <!-- Customer -->
      <div class="input-container">
        <div class="input-field-container">
          <div class="label">First Name*</div>
          <input type="text" id="firstName" formControlName="firstName" placeholder="First Name*" [disabled]="isDisabled" required/>
          
          
          <mat-error class="error" *ngIf="entryForm?.get('firstName')?.touched && entryForm?.get('firstName')?.hasError('required')">
            First Name is required
          </mat-error>
        </div>
        <div class="input-field-container">
          <div class="label">Last Name</div>
          <input type="text" id="lastName" formControlName="lastName" placeholder="Last Name" [disabled]="isDisabled" />
             
        </div>
        <div class="input-field-container">
          <div class="label">Email*</div>
          <input type="email" id="email" formControlName="email" placeholder="Email*" [disabled]="isDisabled" required/>
          <mat-error class="error" *ngIf="entryForm?.get('email')?.touched && entryForm?.get('email')?.hasError('required')">
            Email is required
          </mat-error>
          @if (entryForm.controls['email'].hasError('email')) {
            <div class="error">Please enter valid email.</div>
            }
        </div>
        <div class="input-field-container">
          <div class="label">Phone Number*</div>
          <input type="tel" id="phone_number" formControlName="phone_number" placeholder="Phone Number*" maxlength="13" [disabled]="isDisabled" (keypress)="preventNonNumeric($event)" required/>

          <mat-error class="error" *ngIf="entryForm?.get('phone_number')?.touched && entryForm?.get('phone_number')?.hasError('required')">
            Phone Number is required
          </mat-error>
          @if (entryForm.controls['phone_number'].hasError('minlength')) {
            <div class="error">Please enter valid phone number.</div>
            }
        </div>
      
        <div class="input-field-container" *ngIf="isManualEntry">
          <div class="receipt-number-container">
         
          <div class="label">Receipt Number*</div>
          <mat-icon class="info-icon" (click)="showHelpDialog()">help</mat-icon>
          <a  class="info-text desktop-link span roboto-medium-black-12px" (click)="showHelpDialog()">Sample Receipt Number </a>
        </div>
          <div class="input-field-container-receipt">
            <input type="text" formControlName="field1">
            <label for="input2" class="hyphen">-</label>
            <input type="text" formControlName="field2">
            <label for="input2" class="hyphen">-</label>
            <input type="text"  formControlName="field3" (keypress)="preventNonNumeric($event)">
           
          </div>
          <mat-error class="error" *ngIf="entryForm?.get('field1')?.touched && entryForm?.get('field1')?.hasError('required') || entryForm?.get('field2')?.touched && entryForm?.get('field2')?.hasError('required') || entryForm?.get('field3')?.touched && entryForm?.get('field3')?.hasError('required')" >
            Receipt Number is required
          </mat-error>
          
        </div>
        <div class="input-field-container" *ngIf="isManualEntry">
          <div class="label">Receipt Amount*</div>
          <input type="text" placeholder="Receipt Amount*" formControlName="receipt_amount"  (keypress)="preventNonNumericInRcAmount($event)">
          <mat-error class="error" *ngIf="entryForm?.get('receipt_amount')?.touched && entryForm?.get('receipt_amount')?.hasError('required')">
            Receipt Amount is required
          </mat-error>
        </div>
       
       
      </div>

      <div class="terms-and-conditions-container">
        <input type="checkbox" class="check-box" formControlName="terms_conditions" [disabled]="isDisabled"  required>
        <div class="i-have-read-and-unde-1 m3labelmedium">
          <span class="span0 roboto-medium-black-12px">I have read and understood the competition </span><a
          [routerLink]="['/terms-conditions']"  [queryParams]="{ src:'tnc',ld:lucky_draw_hdr_id}" target="_blank" class="link"><span class="span roboto-medium-black-12px">terms and
              conditions</span></a><span class="span roboto-medium-black-12px">.</span>
        </div>
      </div>
      <div class="register-me-container">
        <input type="checkbox" class="check-box" formControlName="register_me"   required>
        <div class="i-have-read-and-unde-1 m3labelmedium">
          <span class="span0 roboto-medium-black-12px">Register Me for the <strong>Spend and Win Draw</strong> on stpierres.online</span><span class="span roboto-medium-black-12px">.</span>
        </div>
      </div>
      <div class="terms-and-conditions-container">
        <input type="checkbox" class="check-box"  formControlName="isSubscribed" [disabled]="isDisabled"  >
        <div class="i-have-read-and-unde-1 m3labelmedium">
          <span class="span0 roboto-medium-black-12px"> Sign me up to join the St Pierres Sushi Lovers Club for great
            deals, sushi news, competitions and more!
          </span>
        </div>

      </div>
      <!-- Lucky Draw -->
      <div class="action-container">
        @if(registrationStatus==0){
        <div class="button" (click)="onRegister()" >
          <div class="button-1">Submit</div>
          <img class="send" src="assets/img/send-7.svg" alt="Send" />
        </div>
        }
        
        @if(registrationStatus==1){
          <div class="button">
            <div class="button-1">Submitting</div>
            <span class="spinner-border text-dark" ></span>
          </div>
          }
          @if(registrationStatus==2){
            <div class="button"  >
              <div class="button-1">Submit</div>
              <img class="send" src="assets/img/send-7.svg" alt="Send" />
            </div>
            }
      </div>
    </form>
    <div class="icon-container" style="padding-top: 40px;">
      <div class="privacy-policy-container">
        <a  [routerLink]="['/terms-conditions']"  [queryParams]="{ src:'tnc',ld:lucky_draw_hdr_id}" class="link" target="_blank">
          <div class="link-1 link-2 m3bodylarge">Terms &amp; Conditions</div>
        </a>
        <a href="https://PrivacyPolicy"  [routerLink]="['/terms-conditions']"  [queryParams]="{ src:'pp',ld:lucky_draw_hdr_id}" class="link" target="_blank">
          <div class="link-1 link-2 m3bodylarge">Privacy Policy</div>
        </a>
      </div>
      <div class="group-4">
        <div class="overlap-group4">
          <a href="https://www.facebook.com/StPierresSushi" target="_blank">
          </a>
        </div>
        <div class="overlap-group3">
          <a href="https://www.instagram.com/stpierres_sushi/" target="_blank">
          </a>
        </div>
        <div class="overlap-group2">
          <a href="https://www.tiktok.com/@stpierresnz" target="_blank"></a>
        </div>
      </div>

    </div>
  </div>