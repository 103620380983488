import { Component } from '@angular/core';
import { EntryService } from '../entry/entry.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-entry-activation',
  templateUrl: './entry-activation.component.html',
  styleUrl: './entry-activation.component.scss'
})
export class EntryActivationComponent {
  message: any;
  


  constructor( private EntryService:EntryService,  private route: ActivatedRoute,){

  }

  ngOnInit(){
    const paramValue = this.route.snapshot.queryParams['p']; 
    this.EntryService.getCustomerValidation(paramValue).subscribe(
      res => {
   this.message = res.message;

  }
);

  }

 

}
