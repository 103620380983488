import { Component } from '@angular/core';
import { EntryService } from '../entry/entry.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '../../common/service/storage.service';



@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrl: './terms-conditions.component.scss'
})
export class TermsConditionsComponent {

  tcContent: any;
  entryData: any;
  faqContent: any;
  privacyPolicy: any;

  constructor(private EntryService: EntryService, private sanitizer: DomSanitizer, private route: ActivatedRoute, private storageService: StorageService,) { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.route.queryParams.subscribe(params => {
      const paramValue = this.route.snapshot.queryParams['ld'];
      const source = params['src']; 
      /**to display the content as src value  */
      if (source === 'tnc') {
        this.EntryService.getTnc(paramValue).subscribe((response: any) => {
          this.entryData = response.data;
          this.tcContent = this.sanitizer.bypassSecurityTrustHtml(this.entryData.tc_info ?? "");
        });
      } else if (source === 'faq') {
        
        this.faqContent = ''; // Set faqContent to an empty string initially
        this.EntryService.getTnc(paramValue).subscribe((response: any) => { 
          this.entryData = response.data;
          this.faqContent = this.sanitizer.bypassSecurityTrustHtml(this.entryData.faq_info ?? "");
        });
      } else if(source === 'pp'){
        this.privacyPolicy = ''; // Set faqContent to an empty string initially
        this.EntryService.getTnc(paramValue).subscribe((response: any) => { 
          this.entryData = response.data;
          this.privacyPolicy = this.sanitizer.bypassSecurityTrustHtml(this.entryData.privacy_policy ?? "");
        });
       
      } else{

      }
    });
  }

}
