import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrl: './message-dialog.component.scss'
})
export class MessageDialogComponent {
  @Output() clearFields = new EventEmitter<void>();
  crmSingupURL = environment.crmSingupURL;
  status: any;
  constructor(
    private dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {
    this.status=this.data.status;
  }

  close() {
    this.dialogRef.close();
  }
  
  clearField(){
    this.clearFields.emit();
    this.dialogRef.close();
  }
  openRegisterPage() {
     const id= this.data.customerId;
     const urlWithParam = `${this.crmSingupURL}?src=entry&id=${id}`
    window.open(urlWithParam, '_blank');
  }

}
