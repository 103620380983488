import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenService } from '../../common/service/token.service';

@Injectable({
  providedIn: 'root'
})
export class EntryService {



  luckyDrawAPI=[
    {
      status: "SUCCESS",
      message: "",
      data: {
        shop: {
          id: "345",
          code: "",
          name: ""
        },
        customer: {
          id: "x",
          first_name: "vinayak",
          last_name: "joshy",
          email: "sonuvinayak@gamil.com",
          phone: "12345678",
          type: "x"
        },
        lucky_draw: {
          id: "x",
          code: "",
          name: ""
        },
        r_no: ""
      },
      serverTime: "",
      token: ""
    }
]

constructor(private http: HttpClient,private tokenService: TokenService) { }

// getValidatedData(ldparam:any):any{

//   return this.http.get<any>(environment.apiUrl + "/lucky-draw/validate?ldparam=" + ldparam);
// }

getValidatedData(ldparam:any):Observable<any> {
  return this.tokenService.getToken().pipe(
    map((response) => response),
    catchError((error) => {
      throw error;
    }),
    switchMap((response: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: response,
        }),
      };
      return this.http.get(
        environment.apiUrl + "/lucky-draw/get-draw-details?ldparam=" + ldparam,
        httpOptions
      );
    })
  );

  }

luckyDrawRegistration( entryDetails: any): Observable<any> {
  return this.tokenService.getToken().pipe(
    map((response) => response),
    catchError((error) => {
      throw error;
    }),
    switchMap((response: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: response,
        }),
      };

      return this.http.post(
        environment.apiUrl +"/lucky-draw/register",
        entryDetails,
        httpOptions
      );
    })
  );
}

getTnc(id:any):Observable<any> {
  return this.tokenService.getToken().pipe(
    map((response) => response),
    catchError((error) => {
      throw error;
    }),
    switchMap((response: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: response,
        }),
      };
      return this.http.get(
        environment.apiUrl + "/lucky-draw/get-tnc?id=" + id,
        httpOptions
      );
    })
  );

  }
  getCustomerValidation(paramValue:any):Observable<any> {
    return this.tokenService.getToken().pipe(
      map((response) => response),
      catchError((error) => {
        throw error;
      }),
      switchMap((response: any) => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: response,
          }),
        };
        return this.http.get(
          environment.apiUrl + "/lucky-draw/verify-customer"+"/"+paramValue,
          httpOptions
        );
      })
    );
  
    }
    getActiveLd():Observable<any> {
      return this.tokenService.getToken().pipe(
        map((response) => response),
        catchError((error) => {
          throw error;
        }),
        switchMap((response: any) => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: response,
            }),
          };
          return this.http.get(
            environment.apiUrl + "/lucky-draw/get-active-ld",
            httpOptions
          );
        })
      );
    
      }

      
      
      getCustomerDetailsbyEmail(email: any): Observable<any> {
        return this.tokenService.getToken().pipe(
          map((response) => response),
          catchError((error) => {
            throw error;
          }),
          switchMap((response: any) => {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: response,
              }),
            };
      
            return this.http.get(
              environment.apiUrl +"/customer/get-cust-detail-by-mail/"+ email,
              httpOptions
            );
          })
        );
      }

}



